@font-face {
  font-family: "MaisonNeue";
  src: local("MaisonNeue"),
    url("./assets/fonts/MaisonNeue-Mono.ttf") format("truetype");
}

body {
  background-color: #6e635e;
  overflow: hidden;
  font-family: "MaisonNeue", sans-serif;
}

section {
  height: 100vh;
  width: 100vw;
}

img {
  z-index: 2;
}

.logo {
  position: absolute;
  top: 0;
  padding: 28px;
  width: 214px;
}

.description {
  color: #ff7028;
  z-index: 100;
  position: relative;
  top: 50%;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.mark {
  position: absolute;
  bottom: 0;
  padding: 28px;
  width: 567px;
  height: 567px;
  z-index: 1;
}

.horizontal {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}

.trackH {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marqueeHorizontal 32s linear infinite;
}

.trackV {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marqueeVertical 32s linear infinite;
}

@keyframes marqueeHorizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes marqueeVertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}

.vertical {
  display: none;
  position: absolute;
  right: 50%;
  top: 0;
  height: 100vh;
  z-index: 2;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

@media (max-width: 767px) {
  .logo {
    padding: 20px;
    width: 129px;
  }

  .horizontal {
    display: none !important;
  }

  .vertical {
    display: block !important;
  }

  .mark {
    padding: 20px;
    height: unset;
    width: 90vw;
  }
}
